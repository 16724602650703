.dropdown-toggle::after {
  border-bottom: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}