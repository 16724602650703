$background-primary-color: #eaeaea;
$background-secondary-color: #cacfd3;
$accent: #084588;
$elements-secondary-color: #EC6A06;
$white: #FFFFFF;
$text-color: #0e1922;
$positive: #26a69a;
$negative: #ef5350;

$xs-text-size: 1rem;
$s-text-size: 1.2rem;
$m-text-size: 1.5rem;
$l-text-size: 2rem;
$xl-text-size: 3rem;
$xxl-text-size: 4rem;

$tablet-device: 768px;

.header-title {
  font-size: $l-text-size;
  color: $white;
  @media (max-width: $tablet-device) {
    font-size: $m-text-size;
  }
}

.logo {
  height: 15vmin;
  margin: 2%;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.photobox-delete-button {
  color: $negative;
  font-size: $xl-text-size;
  background-color: $white;
  border: 1px solid $negative;
  border-radius: 50%;
}

.delete-place-button {
  color: $white;
  font-size: $l-text-size;
  background-color: $accent;
  border: 1px solid $white;
  border-radius: 50%;
  position: absolute;
  right: -30px;
  @media (max-width: $tablet-device) {
    font-size: $m-text-size;
    right: -20px;
    top: 5px;
  }
}

.home-button {
  color: $white;
  font-size: $xxl-text-size;
  margin-right: 2rem;
  @media (max-width: $tablet-device) {
    font-size: $l-text-size;
  }
}

//changes size of all radio inputs in app
input[type=radio] {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $background-primary-color;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.header {
  background-color: $accent;
  padding: 3rem;
  display: flex;
  align-items: center;
  
  @media (max-width: $tablet-device) {
    padding: 2rem 1rem 0rem 1rem;
  }
}

.logo {
  height: 15vmin;
  width: 15vmin;
  margin: 2%;
  cursor: pointer;
}

.place-name {
  color: $white;
  vertical-align: 'middle';
  margin: '0';
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.main-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 64px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: $tablet-device) {
    font-size: 32px;
  }
}

.choose-place-input-label {
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.input-type-text {
  height: 7rem;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 4rem;
  }
}

.save-button {
  background-color: $accent !important;
  color: $white;
  width: 70vw;
  height: 8rem;
  font-size: 2.2rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 4rem;
  }
}

.spinner-text {
  font-size: 2.2rem;
  margin-left: 1rem;
  margin-bottom: 0;
  @media (max-width: $tablet-device) {
    font-size: 1.2rem;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.search-text {
  font-size: 2.2rem;
  margin: 0rem;
  @media (max-width: $tablet-device) {
    font-size: 1.2rem;
  }
}

.alert {
  width: 100%;
  height: 7rem;
  font-size: 2rem;
  text-align: center;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 4rem;
  }
}

.home-page-button {
  background-color: $accent;
  color: $white;
  width: 70vw;
  height: 12rem;
  font-size: 2.2rem;
  &:hover {
    background-color: $accent;
  }
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 6rem;
  }
}

.home-page-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
  @media (max-width: $tablet-device) {
    margin-top: 2rem;
  }
}

.toast-container {
  width: 92%;
  padding: 2rem;
  position: fixed;
  @media (max-width: $tablet-device) {
    width: 80%;
  }
}

.toast {
  width: 100%;
  position: absolute;
  top: 20px;
}

.toast-header {
  font-size: 1.8rem;
  justify-content: space-between;
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.input-type-select {
  height: 7rem;
  font-size: $l-text-size;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 90vw;
  @media (max-width: $tablet-device) {
    font-size: $m-text-size;
    height: 4rem;
  }
}

.choose-worker-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 64px;
  font-weight: bold;
  @media (max-width: $tablet-device) {
    font-size: 32px;
  }
}

.choose-worker-input-label {
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
  }
}

.aztec-container {
  padding: 2rem;
  background-color: $accent;
  @media (max-width: $tablet-device) {
    padding: 1rem 2rem;
  }
}

.scan-button-label {
  color: $white;
  font-weight: normal;
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.scan-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  border-radius: 5px;
  width: 15rem;
  height: 15rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    width: 8rem;
    height: 8rem;
  }
}

.scan-button {
  background-color: $accent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  color: $white;
  border: none;
  width: 12rem;
  height: 12rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    width: 6rem;
    height: 6rem;
  }
}

.registry-number-label {
  color: $white;
  font-weight: normal;
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.search-user-input {
  height: 7rem;
  font-size: 2rem;
  margin-bottom: 2rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 4rem;
  }
}

.search-user-button {
  width: 100%;
  height: 7rem;
  background-color: $accent !important;
  border: 1px solid $white !important;
  font-size: 1.2rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
    height: 4rem;
  }
}

.input-label {
  display: flex;
  font-size: 2rem;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
  }
}

.input-label::before {
  border-top: 0.3rem solid #084588;
  border-right:  0.3rem solid #084588;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  margin-top: 0.8rem;
  content: '';
  @media (max-width: $tablet-device) {
    margin-right: 0.5rem;
    margin-top: 0.4rem;
  }
}

.user-form-container {
  display: flex;
  justify-content: center;
  @media (max-width: $tablet-device) {
    margin: 2rem;
  }
}

.checkbox-button {
  font-size: 3.2rem;
  @media (max-width: $tablet-device) {
    font-size: 2rem;
  }
}

.result-text {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media (max-width: $tablet-device) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.result-button, .modal-button {
  width: 100%;
  border-radius: 5px;
  font-size: 3rem;
  margin-bottom: 2rem;

  &.button-positive {
    background-color: #26a69a;
    border: 5px solid lighten($positive, 10%);
    height: 15rem;
    @media (max-width: $tablet-device) {
      height: 12rem;
    }
  }

  &.button-negative {
    background-color: $negative;
    border: 5px solid darken($negative, 10%);
    height: 12rem;
    @media (max-width: $tablet-device) {
      height: 10rem;
    }
  }

  &.button-positive-clicked {
    background-color: $positive;
    border: 5px solid $accent;
    height: 15rem;
    @media (max-width: $tablet-device) {
      height: 12rem;
    }
  }

  &.button-negative-clicked {
    background-color: $negative;
    border: 5px solid $accent;
    height: 12rem;
    @media (max-width: $tablet-device) {
      height: 10rem;
    }
  }
}

.alert-error {
  margin-top: 2rem;
}

.modal-title {
  font-size: 4rem;
  text-align: center;
  @media (max-width: $tablet-device) {
    font-size: 2rem;
  }
}

.modal-button-positive, .modal-button-negative {
  width: 80%;
  height: 15rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.modal-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-page-button-container {
  width: 18rem;
  height: 18rem;
  padding: 1rem;

  @media (max-width: $tablet-device) {
    font-size: 1rem;
    width: 12rem;
    height: 11.2rem;
  }
}

.add-page-button {
  height: 100%;
  width: 100%;
  border: 5px dotted $white !important;
  color: $white;
  background-color: $accent !important;
  font-size: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-box-label {
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.save-button-text {
  font-size: 2.2rem;
  margin-left: 1rem;
  margin-bottom: 0;
  @media (max-width: $tablet-device) {
    font-size: 1.5rem;
  }
}

.save-button-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.policy-number-label {
  color: $white;
  font-weight: normal;
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.radio-label {
  color: $white;
  font-size: 1.8rem;
  
  @media (max-width: $tablet-device) {
    font-size: 1rem;
  }
}

.form-check .form-check-input {
  float: none;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem;
  display: flex;
  align-items: center;
}